import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  Observable,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageService } from 'src/app/services/storage.service';
import JSZip from 'jszip';
import { ListItem } from 'src/models/ListItem';



@Component({
  selector: 'app-image-messages',
  templateUrl: './image-messages.component.html',
  styleUrls: ['./image-messages.component.scss'],
})
export class ImageMessagesComponent implements OnInit {
  public items: ListItem[] = [];
  public formArray = new FormArray<FormControl>([]);
  public totalCount: number = 0;
  private accessCode?: string;
  private compressedImagesPath = 'gallery/images-compressed';
  private unCompressedImagesPath = "gallery/images"
  constructor(
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
  ) {}

  public async ngOnInit() {
    this.accessCode = await this.authenticationService.getEventCode();
    this.storageService
      .getDownloadUrlsAndMetadata(`${this.accessCode}/${this.unCompressedImagesPath}`)
      .pipe(
        take(1),
        mergeMap((items) => {
          this.totalCount = items.totalCount;
          return items.files;
        }),
        map((file) => {
          const newLisItem = { title: String(file.metadata?.customMetadata?.['guestName']),
          fileName:file.metadata.name,
           storagePath:`${this.accessCode}/${this.unCompressedImagesPath}/` + file.metadata.name};
          this.items.push(newLisItem);
          const control = new FormControl(false);
          this.formArray.push(control);
          return newLisItem;
        })
      )
      .subscribe((listItem) => {
        console.log('listItem', listItem);
      });
  }

  public fetchAudioBlob(item: ListItem): Observable<Blob> {
    return this.storageService
      .getBlob(item.storagePath)
      .pipe(
        take(1),
        tap((blob) => {
          item.src = URL.createObjectURL(blob);
        })
      )
  }
  public downloadAllItemsAsZip() {
    const zip = new JSZip();
    const observables: Observable<Blob>[] = [];
  
    this.items.forEach((item) => {
      if (item.src) {
        observables.push(this.fetchAudioBlobFromSrc(item.src));
      } else {
        observables.push(this.fetchAudioBlob(item));
      }
    });
  
    forkJoin(observables)
      .pipe(
        take(1), // Ensure this code runs only once
        map((blobArray) => {
          blobArray.forEach((blobData, index) => {
            zip.file(`${this.items[index].title}_${this.items[index].fileName}`, blobData);
          });
        }),
        switchMap(() => zip.generateAsync({ type: 'blob' }))
      )
      .subscribe((zipBlob) => {
        // Generate a ZIP blob and create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.download = `Event_${this.accessCode}_image_messages.zip`;
        downloadLink.click();
      });
  }
  
  public fetchAudioBlobFromSrc(url: string): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          observer.next(blob);
          observer.complete();
        })
        .catch((error) => observer.error(error));
    });
  }

  public firstPlay(item: ListItem) {
    item.isPlayed = true;
    if (!item.src) {
      this.fetchAudioBlob(item).pipe(take(1)).subscribe();
    }
  }

  public deleteItem(item: ListItem, index: number) {
    if (window.confirm(`Are you sure you want to delete this item (${item.title})?`)) {
      this.storageService
        .deleteFile(`${this.accessCode}/${this.unCompressedImagesPath}/` + item.fileName)
        .pipe(take(1))
        .subscribe(() => {
          this.items.splice(index, 1);
          this.formArray.removeAt(index);
          this.totalCount = this.items.length;
          // Delete compressed version
          this.storageService
          .deleteFile(`${this.accessCode}/${this.compressedImagesPath}/` + item.fileName)
          .pipe(
          take(1),
          catchError(()=>{
            alert(`Could not find the compressed image version from: ${item.title}`);
            return of(null)})
          )
          .subscribe();
        });
    }
  }
}

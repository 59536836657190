import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SigninComponent } from '../signin/signin.component';
import { HomeComponent } from '../home/home.component';
import { ImageMessagesComponent } from '../image-messages/image-messages.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public title = '';
  public dropdownOpen = false;
  public isSignInComponentActive: boolean = true;
  public showBackButton:boolean = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
  ) { }

  public async ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
    let route = this.router.routerState.root.snapshot;
    // Checks whether the current route snapshot has a firstChild. If it does, it means that there is a nested route, and the loop continues to execute.
    while (route.firstChild) {
      route = route.firstChild;
    }
    // Set flag to control dropdown show / hide 
    this.isSignInComponentActive = route.routeConfig?.component === SigninComponent;
    // Set Tile
    switch(route.routeConfig?.component)
    {
      case SigninComponent:
        this.title = 'Event Dashboard'
        this.showBackButton = false;
        break;
      case HomeComponent:
        this.title = 'Home'
        this.showBackButton = false;
        break;
      case ImageMessagesComponent:
        this.title = 'Images'
        this.showBackButton = true;
        break;
    }
  });

  }

  public goBack() {
    this.router.navigate(['/home']);
  }

  public logout() {
    this.authenticationService.signOut().pipe(take(1)).subscribe(
      ()=>{
        this.router.navigate(['/']); // Navigate to home page
      }
    );
  }

  public toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  public closeDropdown() {
    this.dropdownOpen = false;
  }
}

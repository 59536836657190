import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, doc, updateDoc, Timestamp, getDoc } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

interface EventDates {
  startDate: Date | null;
  endDate: Date | null;
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private eventDate?:EventDates;
  private hostName?:string;
  private theme?:string
  constructor(private fireStore: Firestore) {}

  public getEventDates(accessCode: string): Observable<EventDates | null> {
    const docRef = doc(this.fireStore, `events/${accessCode}`);
    if(this.eventDate){
      return from([this.eventDate]);
    }
    return from(getDoc(docRef)).pipe(map(doc => {
      if (doc.exists()) {
        const data = doc.data();
        const dates: EventDates = {
          startDate: data?.['startTime']?.toDate() || null,
          endDate: data?.['endTime']?.toDate() || null
        };
        return dates;
      }
      return null;
    }));
  }

  public updateEventDates(accessCode: string, localStartTime: Date, localEndTime: Date): Observable<void> {
    const event = doc(this.fireStore, `events/${accessCode}`);
    const startTimeStamp = Timestamp.fromMillis(localStartTime.getTime());
    const endTimeStamp = Timestamp.fromMillis(localEndTime.getTime());
    return from(updateDoc(event, {
      startTime: startTimeStamp,
      endTime: endTimeStamp
    })).pipe(take(1),tap(()=>{
      this.eventDate = {
        startDate: localStartTime,
        endDate: localEndTime
      };
    }))
  }

  public getHostData(accessCode: string): Observable<string | null>{
    const docRef = doc(this.fireStore, `public_data/${accessCode}`);
    if(this.hostName){
      return from([this.hostName]);
    }
    return from(getDoc(docRef)).pipe(map(doc => {
      if (doc.exists()) {
        const data = doc.data();
        return data?.['hostName']
      }
      return null;
    }));
  }

  public updateHost(accessCode: string, hostName: string): Observable<void> {
    const event = doc(this.fireStore, `public_data/${accessCode}`);
    return from(updateDoc(event, {
      hostName: hostName
    })).pipe(take(1),tap(()=>{
      this.hostName = hostName;
    }));
  }
  public getThemeData(accessCode: string): Observable<string | null>{
    const docRef = doc(this.fireStore, `public_data/${accessCode}`);
    if(this.theme){
      return from([this.theme]);
    }
    return from(getDoc(docRef)).pipe(map(doc => {
      if (doc.exists()) {
        const data = doc.data();
        return data?.['theme']
      }
      return null;
    }));
  }
  public updateTheme(accessCode: string, theme: string): Observable<void> {
    const event = doc(this.fireStore, `public_data/${accessCode}`);
    return from(updateDoc(event, {
      theme: theme
    })).pipe(take(1),tap(()=>{
      this.theme = theme;
    }));
  }
  private generateEventCode(length: number): string {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
import { Injectable } from '@angular/core';
import { Auth,getAuth,onAuthStateChanged,signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private auth: Auth,
    private fireStore: Firestore
  ) { }

  public signIn(params: SignIn): Observable<any> {
    return from(signInWithEmailAndPassword(this.auth,
      params.email, params.password
    ));
  }

  public signOut(){
    return from(signOut(this.auth))
  }

  public getUser(){
    return getAuth().currentUser
  }

  public async getEventCode(): Promise<any>{
    const docRef = doc(this.fireStore, `user_events/${this.getUser()?.uid}`);
    const result = await getDoc(docRef);
    return result.data()?.['eventIds'][0];
  }

}

type SignIn = {
  email: string;
  password: string;
}

type FirebaseError = {
  code: string;
  message: string
};